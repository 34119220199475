import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import axios from "axios"
import {GET_USER, SET_LOCATION} from '../config/variables';

export default createStore({
  state: {
    location: null,
    userData: [],
    selectedRoad: null,
    isRoadSelected: false,
    checkpointList: [],
    JWT: [],
  },
  plugins: [createPersistedState()],
  getters: {
    getLocation(state) {
      let location = state.location;
      return (location);
    },

    getUser(state){
      return state.userData;
    },
    getSelectedRoad(state) {
      let selectedRoad = state.selectedRoad;
      return (selectedRoad);
    },
    getIsRoadSelected(state) {
      let isRoadSelected = state.isRoadSelected;
      return (isRoadSelected);
    },
    getCheckpointList(state) {
      return state.checkpointList;
    },
    isCheckpointInList: (state) => (id) => {
      return state.checkpointList.includes(id);
    },
    getToken(state) {
      return state.JWT;
    },
  },
  mutations: {
    SET_LOCATION(state, location) {
      state.location = location;
    },
    SET_USER(state, user) {
      state.userData = user;
    },
    SET_SELECTED_ROAD(state, selectedRoad){
      state.selectedRoad = selectedRoad;
    },
    SET_IS_ROAD_SELECTED(state, isRoadSelected){
      state.isRoadSelected = isRoadSelected;
    },
    SET_CHECKPOINT_LIST(state, listItemId){
      state.checkpointList.push(listItemId);
    },
    REMOVE_CHECKPOINT_LIST(state, listItemId){
      state.checkpointList = state.checkpointList.filter(item => item !== listItemId);
    },
    SET_TOKEN(state, jwt){
      state.JWT = jwt;
    },
    RESET_STATE(state) {
      state.JWT = [];
      state.userData = [];
      state.location = null;
    },
  },
  actions: {
    setLocation({ commit }, location) {
      commit('SET_LOCATION', location);
    },
    setUser({ commit }, userData) {
      commit('SET_USER', userData);
    },
    setSelectedRoad({ commit }, selectedRoad){
      commit('SET_SELECTED_ROAD', selectedRoad);
    },
    setIsRoadSelected({ commit }, isRoadSelected){
      commit('SET_IS_ROAD_SELECTED', isRoadSelected);
    },
    setCheckpointList({ commit }, listItemId){
      commit('SET_CHECKPOINT_LIST', listItemId)
    },
    removeCheckpointList({commit}, listItemId){
      commit('REMOVE_CHECKPOINT_LIST', listItemId);
    },
    login({ dispatch }, jwt){
      dispatch('setToken', jwt);
      dispatch('loadUser');
      dispatch('periodicallySendLocation');
    },
    setToken({ commit }, jwt){
      commit('SET_TOKEN', jwt);

      axios.defaults.headers.common = {
        'Authorization': `bearer ${jwt}`
      }

    },
    loadUser({ commit }){
      axios.get(GET_USER)
          .then((response) => {
            commit('SET_USER', response.data);
          });
    },
    logout({ commit }){
      commit('RESET_STATE');
      window.localStorage.timeLastSentLocation = null;
    },
    periodicallySendLocation({ dispatch }){
      if (window.localStorage.timeLastSentLocation - new Date().getTime() < 600000) {
        return;
      }
      navigator.permissions.query({
        name: 'geolocation'
      }).then(function (result) {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition((position) => {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            dispatch('setLocation', location);
            axios.post(SET_LOCATION, {
              lat: position.coords.latitude,
              long: position.coords.longitude

            })
                .then(() => {
                  window.localStorage.timeLastSentLocation = new Date().getTime();
                })
                .catch(function (error) {
                  console.log(error);
                });
          });
        }
      });
    }
  },
  modules: {},
})
