<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
import store from "@/store";
import axios from "axios";

export default {
    data() {
        return {
            loader: true,
        }
    },
    created() {
        this.handlePermission();
        setInterval(() => {
            this.handlePermission();
        }, 600000);
    },
    mounted() {
        const jwt = store.getters.getToken;
        if (jwt) {
            axios.defaults.headers.common = {
                'Authorization': `bearer ${jwt}`
            }
        }
    },
    methods: {
        handlePermission() {
            const token = store.getters.getToken;
            if (token !== null && token !== undefined && token.length > 0) {
                this.$store.dispatch('periodicallySendLocation');
            }
        },
    },
}
</script>

<style lang="scss">
@import "./scss/app.scss";

.screenHeight {
    overflow: hidden;
}
</style>
