import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Toast, { POSITION }  from "vue-toastification";
import "vue-toastification/dist/index.css";

import '@popperjs/core';

// eslint-disable-next-line no-unused-vars
import * as bootstrap from 'bootstrap'
import axios from "axios";


createApp(App)
    .use(router)
    .use(store)
    .use(Toast, {
        position: POSITION.BOTTOM_RIGHT
    })
    .mount('#app')


let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            store.dispatch('logout');
            router.push({name: 'SignIn'});
        }
        return Promise.reject(error);
    }
);

