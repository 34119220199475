export const GMAPS_KEY = process.env.VUE_APP_GOOGLE_MAPS_KEY;
export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const MAP_ID = process.env.VUE_APP_MAP_ID;
export const LOGIN_URL = BASE_URL+'login';
export const CHAT_TOKEN = BASE_URL+'chat-token';
export const GET_URL = BASE_URL+'poi/';
export const GET_USER = BASE_URL+'me';
export const SET_LOCATION = BASE_URL+'location';
export const UPDATE_USER = BASE_URL + 'me';
export const REGISTER_URL = BASE_URL+'register';
