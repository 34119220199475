import {
  createRouter,
  createWebHistory
} from 'vue-router';
import store from '@/store'

const routes = [{
    path: '/',
    name: 'App',
    component: () => import('../App.vue'),
    beforeEnter: (to, from, next) => {
      let isAuthenticated = false;
      const token = store.getters.getToken;
      if (token !== null && token !== undefined && token.length > 0) {
        isAuthenticated = true;
      }
      if (isAuthenticated === true) {
        next({ path: '/home' });
      } else {
        next({ path: '/signin' });
      }
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter: checkIfUserIsSignIn,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import('../views/SignInPage.vue'),
  },
  {
    path: '/checkpoints',
    name: 'Checkpoints',
    component: () => import('../views/Checkpoints.vue'),
    beforeEnter: checkIfUserIsSignIn,
  },
  {
    path: '/myCheckpointsMap',
    name: 'My Checkpoints on map',
    component: () => import('../views/CheckpointsOnMap.vue'),
    beforeEnter: checkIfUserIsSignIn,
  },
  {
    path: '/allLocations',
    name: 'All locations',
    component: () => import('../views/AllLocations.vue'),
    beforeEnter: checkIfUserIsSignIn,
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/RegisterPage.vue'),
  },
  {
    path: '/chat',
    name: 'Chat messages',
    component: () => import('../views/ChatMessages.vue'),
    beforeEnter: checkIfUserIsSignIn,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    beforeEnter: checkIfUserIsSignIn,
    children: [
      {
        path: '',
        name: 'menu',
        component: () => import('../views/settings/Menu.vue'),
      },
      {
        path: 'profile',
        name: 'settings.profile',
        component: () => import('../views/settings/profile/TeamProfile.vue'),
      },
      {
        path: 'profile/edit',
        name: 'settings.profile.edit',
        component: () => import('../views/settings/profile/Edit.vue'),
      },
      {
        path: 'legalPages/privacy',
        name: 'settings.privacy',
        component: () => import('../views/settings/legalPages/Privacy')
      },
      {
        path: 'legalPages/termsAndConditions',
        name: 'settings.TermsAndConditions',
        component: () => import('../views/settings/legalPages/TermsAndConditions')
      },
      {
        path: 'legalPages/support',
        name: 'settings.Support',
        component: () => import('../views/settings/legalPages/Support')
      },
    ]
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

function checkIfUserIsSignIn (to, from, next) {
  let isAuthenticated = false;
  const token = store.getters.getToken;
  if (token !== null && token !== undefined && token.length > 0) {
    isAuthenticated = true;
  }
  if (isAuthenticated === true) {
    next();
  } else {
    next({ path: '/signin' });
  }
}
